import { format, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from 'primereact/inputtextarea';
import { FieldInfo } from "../Components/FieldInfo";
import { OverlayPanelMenu } from "../Components/OverlayPanelMenu";
import { getData, patchData } from "../feathers";
import { useCurrentUser } from "../Persistence/CurrentUserContext";
import { ReminderDialog } from "../Reminders/ReminderDialog";
import { JobOpeningForm } from "./JobOpeningForm";
import { EAADivider } from "../Components/EAADivider";
import { ConfirmDialog } from "../Components/ConfirmDialog";
import { classNames } from "primereact/utils";
import { Tag } from "primereact/tag";
import { JobOpeningSheet } from "./JobOpeningSheet";
import { EditorChangesChecked } from "../Components/EditorChangesChecked";
import { ENUM_ROUTES } from "../Navigation/Routes";
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";
import { ENUM_ICONS } from "../Components/Icons";

const numberFormat = (value) => new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value);
const boolFormat = (value) => value ? 'Ja' : 'Nein';

export const emptyJobOpeningComment = { id: null, version: 1, active: 1, comment: '' }


export const JobOpeningData = () => {
	const currentUser = useCurrentUser();
	const configurationsList = useConfigurationsList();
	const { jobOpeningId } = useParams();
	const [jobOpening, setJobOpening] = useState();
	const [jobOpeningForm, setJobOpeningForm] = useState();
	const [comment, setComment] = useState();
	const [active, setActive] = useState(0);
	const [readonly, setReadonly] = useState(true);
	const [jobOpeningComment, setJobOpeningComment] = useState({ ...emptyJobOpeningComment });
	const [displayReminderDialog, setDisplayReminderDialog] = useState(false);
	const [displayDialog, setDisplayDialog] = useState(false);
	const [displayCommentDialog, setDisplayCommentDialog] = useState(false);
	const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
	const [displayConfirmDeleteCommentDialog, setDisplayConfirmDeleteCommentDialog] = useState(false);
	const [formHasErrors, setFormHasErrors] = useState();
	const navigate = useNavigate();

	useEffect(() => {
		if (configurationsList.eaaModules && configurationsList.eaaModules.JOBOPENING !== '1') {
			navigate('/' + ENUM_ROUTES.DASHBOARD)
		}
	}, [])

	useEffect(() => {
		if (jobOpeningId) {
			getJobOpeningData(jobOpeningId)
		}
	}, [jobOpeningId])

	const setJobOpeningFromForm = (jobOpening) => {
		setJobOpeningForm(jobOpening);
	}

	const getJobOpeningData = async (id) => {
		await getData('job-openings', jobOpeningId).then((jobOpening) => {
			filterAndSortComments(jobOpening);
		})
	}

	const filterAndSortComments = (jobOpening) => {
		if (jobOpening.jobOpeningComments) {
			jobOpening.jobOpeningComments = jobOpening.jobOpeningComments.filter(entry => entry.active);
			jobOpening.jobOpeningComments.sort((x, y) => { const a = y.commentDate; const b = x.commentDate; return a == b ? 0 : a > b ? 1 : -1; })
		}
		setJobOpening(jobOpening);
		setReadonly(jobOpening.status.alias === 'INACTIVE' || currentUser.permissions === 'READER')
	}

	const patchJobOpening = async () => {
		jobOpeningForm.eaaCaseId = (jobOpeningForm.eaaCase ? jobOpeningForm.eaaCase.id : jobOpeningForm.eaaCaseId);
		jobOpeningForm.status = jobOpeningForm.status.alias;
		jobOpeningForm.jobType = jobOpeningForm.jobType.alias;
		await patchData('job-openings', jobOpeningForm).then((jobOpening) => {
			setDisplayDialog(false);
			filterAndSortComments(jobOpening);
			setJobOpening(jobOpening);
		})
	}

	const patchJobOpeningComment = async () => {
		jobOpeningComment.comment = comment;
		jobOpeningComment.userId = currentUser.id;
		jobOpeningComment.jobOpeningId = jobOpening.id
		await patchData('job-opening-comments', jobOpeningComment).then(async () => {
			setComment('');
			setJobOpeningComment({ ...emptyJobOpeningComment })
			setDisplayCommentDialog(false);
			await getData('job-openings', jobOpeningId).then(() => {
				getJobOpeningData(jobOpeningId)
			})
		})
	}



	const onDeleteCommentHandler = async () => {
		jobOpeningComment.active = 0;

		await patchData('job-opening-comments', jobOpeningComment).then(async () => {
			setComment('');
			setJobOpeningComment({ ...emptyJobOpeningComment })
			setDisplayConfirmDeleteCommentDialog(false);
			await getData('job-openings', jobOpeningId).then(() => {
				getJobOpeningData(jobOpeningId)
			})
		})
	}

	const handleDeleteComment = (jobOpeningComment) => {
		setJobOpeningComment(jobOpeningComment);
		setDisplayConfirmDeleteCommentDialog(true);
	}
	const handleExportJobOpening = () => {
		alert('Stellenprofil exportieren (in Bearbeitung)');
	}
	const handleEditJobOpening = () => {
		setJobOpeningForm({ ...jobOpening });
		setDisplayDialog(true);
	}
	const handleEditJobOpeningComment = (jobOpeningComment) => {
		setJobOpeningComment(jobOpeningComment);
		setComment(jobOpeningComment.comment);
		setDisplayCommentDialog(true);
	}
	const handleUseForReminder = () => {
		setDisplayReminderDialog(true)
	}

	const handleStatusEAACase = async () => {
		jobOpening.active = { alias: (jobOpening.active.alias === 'INACTIVE' ? 'OPEN' : 'INACTIVE') }
		await patchData('job-openings', { id: jobOpening.id, active: 0, version: jobOpening.version })
	}

	const renderComments = () => {
		return jobOpening.jobOpeningComments.map((jobOpeningComment) =>
			<div className='flex flex-column' key={'comment_' + jobOpeningComment.id}>
				<div className='flex flex-row w-full gap-3'>
					<div className='flex color-text-blue font-medium'>
						{jobOpeningComment.user.lastname + ', ' + jobOpeningComment.user.firstname}
					</div>
					<div className='flex w-6rem text-color font-medium'>
						{format(parseISO(jobOpeningComment.commentDate), 'yyyy-MM-dd')}
					</div>
				</div>
				<div className='flex flex-row w-full'>
					<div className='flex-1 text-base color-lessblack editor-changes-checked' dangerouslySetInnerHTML={{ __html: jobOpeningComment.comment }} />
					<div className='flex w-2rem pt-1 color-lessblack font-medium gap-3'>
						{currentUser.id !== jobOpeningComment.userId || readonly ? '' : <i className={ENUM_ICONS.TRASH + "del-icon"} onClick={() => { handleDeleteComment(jobOpeningComment) }} />}
						{currentUser.id !== jobOpeningComment.userId || readonly ? '' : <i className={ENUM_ICONS.PENCIL + "edit-icon"} onClick={() => { handleEditJobOpeningComment(jobOpeningComment) }} />}
					</div>
				</div>

			</div>)
	}

	const items = [{ label: 'bearbeiten', icon: ENUM_ICONS.PENCIL, command: handleEditJobOpening, disabled: readonly },
	{ label: 'Exportieren', icon: ENUM_ICONS.EXPORT, command: handleExportJobOpening },
	{ separator: true },
	{ label: (readonly ? 'Aktiv setzen' : 'Inaktiv setzen'), icon: (readonly ? ENUM_ICONS.REACTIVATE : ENUM_ICONS.TRASH), command: () => { setDisplayConfirmDialog(true) }, disabled: currentUser.permissions === 'READER' }]

	const renderJobOpeningData = () => {
		return <div className="eaa-maincontent flex flex-column">
			<div className='flex m-0 w-full h-full '>
				{!jobOpening ? '' : <div className='mx-auto eaa-maincontent flex flex-column'>
					<div className="flex flex-column">
						<div className='flex grid'>
							<div className='col flex flex-column text-4xl '>
								<div className='flex justify-content-center'>
									{jobOpening.description}
								</div>
								<div className='flex text-lg justify-content-center my-2 gap-2'>
									<div>{jobOpening ? `#JO${jobOpening.id} erstellt am ${format(parseISO(jobOpening.createdAt), 'yyyy-MM-dd')}` : ''}</div>
									<Tag key='tagStatus' className={'mr-2 text-white '} style={{ backgroundColor: jobOpening.status.color }} rounded value={jobOpening.status.display} />
								</div>
							</div>
						</div>
						<div className='flex overflow-hidden'>
							<FieldInfo label='Unternehmen  ' content={jobOpening.company.name} />
							<FieldInfo label='Fall' content={jobOpening.eaaCase ? jobOpening.eaaCase.name : 'keinem Fall zugeordnet'} />
						</div>
						<div className='flex overflow-hidden'>
							<FieldInfo label='Ort' content={jobOpening.location} />
							<FieldInfo label='Startdatum' content={jobOpening.openingDate} />
							<FieldInfo label='Typ' content={jobOpening.jobType.display} />
						</div>
					</div>

					<div className='flex flex-column flex-grow-1 surface-ground mt-4 mx-2'>
						<div className='flex text-600 text-base mb-1'>
							Erläuterungen zur Stelle
						</div>
						<div className='editor-changes-checked' dangerouslySetInnerHTML={{ __html: jobOpening.explanation }} />
					</div>

					<div className="flex flex-column mt-4 mr-3">
						<div className='flex m-2 text-600 text-base'>
							{(jobOpening && jobOpening.jobOpeningComments ? jobOpening.jobOpeningComments.length : 'keine') + ' Kommentare'}
						</div>

						<div className='flex bg-white shadow-2 m-2 w-full overflow-y-auto'>
							<div className='flex flex-column w-full gap-4 m-4 surface-section '>
								<EAADivider className={readonly ? 'hidden' : 'mt-2 '} icon={ENUM_ICONS.PLUS} label='Neuer Kommentar' key='jobopening-dialog' onClickHandler={() => { setDisplayCommentDialog(true) }} />
								{jobOpening && jobOpening.jobOpeningComments ? renderComments() : ''}
							</div>
						</div>
					</div>

					<Dialog header={jobOpeningComment.id ? 'Kommentar ändern' : 'Neuen Kommentar hinzufügen'} visible={displayCommentDialog} onHide={() => setDisplayCommentDialog(false)} id="dialog-jobopeningcomment" className='eaa-inputdialog' >
						<div className='flex w-full my-4'>
							<span className="p-float-label w-full">
								<EditorChangesChecked id='comment' value={comment} setValue={(value) => setComment(value)} label='Beschreibung' setShowDialog={() => { }} />
							</span>
						</div>
						<div className="flex flex-row card-container blue-container flex-row-reverse mx-4 my-2">
							<Button type="button" className="flex-order-1 button-cancel" label='Abbrechen' onClick={() => setDisplayCommentDialog(false)} />
							<Button onClick={patchJobOpeningComment} disabled={!jobOpeningComment || jobOpeningComment === ''} className="flex-order-0 ml-3 " label='Speichern' />
						</div>
					</Dialog>

					<ReminderDialog displayDialog={displayReminderDialog} setDisplayDialog={setDisplayReminderDialog} header='Stellenprofil' name={jobOpening.company.name} serviceId={jobOpening.id} serviceName='job-openings' userId={currentUser.id} />
					<Dialog header='Stellenprofil ändern' visible={displayDialog} onHide={() => setDisplayDialog(false)} id="dialog-jobopening-details" className="eaa-inputdialog" >
						<JobOpeningForm companyId={jobOpening.company.id} jobOpeningId={jobOpeningId} setFormHasErrors={setFormHasErrors} setJobOpening={setJobOpeningFromForm} key={'JobOpeningForm'} setShowDialog={() => { }} />
						<div className="flex flex-row card-container blue-container flex-row-reverse mx-4 my-2">
							<Button type="button" className="flex-order-1 button-cancel" label='Abbrechen' onClick={() => setDisplayDialog(false)} />
							<Button onClick={patchJobOpening} disabled={formHasErrors} className="flex-order-0 ml-3 " label='Speichern' />
						</div>
					</Dialog>
					<ConfirmDialog title={'Stellenprofil ' + (readonly ? 'reaktivieren' : 'inaktivieren')} message={`Sind Sie sicher, das Stellenprofil zu ${(readonly ? 'reaktivieren' : 'inaktivieren')}`} labelOk='Ja'
						handleOnClick={handleStatusEAACase} displayConfirmDialog={displayConfirmDialog} setDisplayConfirmDialog={setDisplayConfirmDialog} />

					<ConfirmDialog title='Kommentar entfernen' message={`Sind Sie sicher, Ihren Kommentar vom ${jobOpeningComment.commentDate ? format(parseISO(jobOpeningComment.commentDate), 'yyyy-MM-dd') : ''}\nzu löschen?'`} labelOk='Ja'
						handleOnClick={onDeleteCommentHandler} displayConfirmDialog={displayConfirmDeleteCommentDialog} setDisplayConfirmDialog={setDisplayConfirmDeleteCommentDialog} />
				</div>}
			</div>
		</div>
	}

	return (
		<div className="flex m-0 w-full h-full ">
			<div className="flex m-0 w-full h-full  overflow-y-auto">
				<div className='flex mx-auto eaa-maincontent mt-0' style={{ minWidth: '400px' }}>
					{!jobOpening ? '' : renderJobOpeningData()}
				</div>
			</div>
			<div className="flex bg-white eaa-sidecontent h-full" >
				<div className="flex flex-column eaa-sidecontent pt-2" >
					<div className='grid m-0 p-0'>
						<div className="col">
							<ul className="card-container pl-3 m-0 list-none flex overflow-x-auto select-none justify-content-start">
								<li>
									<a className={classNames('p-ripple cursor-pointer px-4 py-2 flex align-items-center border-bottom-2 hover:border-500 transition-colors transition-duration-150 border-noround', { 'border-blue-500 text-blue-500 hover:border-blue-500': active === 0, 'text-700 border-transparent': active !== 0 })} onClick={() => setActive(0)}>
										<i className={ENUM_ICONS.JOBPROFILE + "fa-lg mr-2"}></i>
									</a>
								</li>
							</ul>
						</div>
						<div className="col-fixed w-2rem flex  align-content-center">
							<div className='flex align-items-center w-full'>
								<OverlayPanelMenu items={items} />
							</div>
						</div>
					</div>
					<div className="col-fixed eaa-sidecontent">
						{active !== 0 ? '' : !jobOpening ? '' : <JobOpeningSheet jobOpening={jobOpening} />}
					</div>

				</div>
			</div>
		</div>
	)

}
import { format, getTime, parseISO } from 'date-fns';
import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Tag } from 'primereact/tag';
import { useEffect, useRef, useState } from 'react';
import { getData, patchData } from '../feathers';
import { ENUM_ROUTES } from '../Navigation/Routes';
import { useBreadcrumbsAdd } from '../Persistence/Breadcrumbs';
import { CompaniesProvider } from '../Persistence/CompaniesContext';
import { useConfigurationsList } from '../Persistence/ConfigurationsContext';
import { useCurrentUser } from '../Persistence/CurrentUserContext';
import { useJobOpeningsList } from '../Persistence/JobOpeningsContext';
import { ReminderDialog } from '../Reminders/ReminderDialog';
import { JobOpeningForm } from './JobOpeningForm';
import { useNavigate } from 'react-router-dom';
import { ENUM_ICONS } from '../Components/Icons';


export const JobOpeningDataview = () => {
	const addBreadcrumb = useBreadcrumbsAdd();
	const calendarRef = useRef();
	const currentUser = useCurrentUser();
	const jobOpeningsList = useJobOpeningsList();
	const configurationsList = useConfigurationsList();
	const [paginationFirst, setPaginationFirst] = useState(0);
	const [paginationRows, setPaginationRows] = useState(15);
	const [jobOpening, setJobOpening] = useState();
	const [displayReminderDialog, setDisplayReminderDialog] = useState(false);
	const [displayCompanyDialog, setDisplayCompanyDialog] = useState(false);
	const [formHasErrors, setFormHasErrors] = useState();
	const [filterOpeningDate, setFilterOpeningDate] = useState();

	const [filters, setFilters] = useState({
		'location': { value: null, matchMode: FilterMatchMode.CONTAINS },
		'description': { value: null, matchMode: FilterMatchMode.CONTAINS },
		'status': { value: null, matchMode: FilterMatchMode.IN },
		'company.name': { value: null, matchMode: FilterMatchMode.CONTAINS },
		'active': { value: (null), matchMode: FilterMatchMode.EQUALS },
		'jobType': { value: (null), matchMode: FilterMatchMode.EQUALS },
		'millisecondsOpeningDate': { value: null, matchMode: FilterMatchMode.BETWEEN },
	});
	const navigate = useNavigate();
	useEffect(() => {
		if (configurationsList.eaaModules && configurationsList.eaaModules.JOBOPENING !== '1') {
			navigate('/' + ENUM_ROUTES.DASHBOARD)
		}
	}, [])

	const handleSelectJobOpening = async (id) => {
		await getData('job-openings', id).then((jobOpening) => {
			const label = jobOpening.company.name + ' | ' + jobOpening.description;
			addBreadcrumb(`${label.length > 50 ? label.substring(0, 49) : label}`, `${ENUM_ROUTES.JOBPROFILES}/` + id);
		})
	}

	const patchJobOpening = async () => {

		await patchData('job-openings', jobOpening, currentUser).then((jobOpening) => {
			const label = jobOpening.company.name + ' | ' + jobOpening.description;
			addBreadcrumb(`${label.length > 50 ? label.substring(0, 49) : label}`, `${ENUM_ROUTES.JOBPROFILES}/` + jobOpening.id);
		})
	}

	const paginatorTemplate = {
		layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
		'RowsPerPageDropdown': (options) => {
			const dropdownOptions = [
				{ label: 15, value: 15 },
				{ label: 30, value: 30 },
				{ label: 45, value: 45 }
			];

			return (
				<>
					<span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>Einträge pro Seite: </span>
					<Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
				</>
			);
		},
		'CurrentPageReport': (options) => {
			return (
				<span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
					{options.first} - {options.last} von {options.totalRecords}
				</span>
			)
		}
	};

	const jobTypeItemTemplate = (option) => {
		return <div>{option.display}</div>
	}
	const jobTypeRowFilterTemplate = (options) => {
		const listStatus = configurationsList.JobOpeningTypeList;
		return <Dropdown value={options.value} className='w-11rem' optionLabel="display" optionValue="alias" options={listStatus} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={jobTypeItemTemplate} placeholder='Typ wählen' />;
	}

	const statusSelectedTemplate = (option) => {
		const listStatus = configurationsList && configurationsList.JobOpeningStatusList ? configurationsList.JobOpeningStatusList : null
		if (option) {
			const status = listStatus.find(e => option === e.alias);
			return (
				<div className="flex text-xm" style={{ color: status.color }}>
					{status.display}
				</div>
			);
		}
		return "";
	}
	const statusItemTemplate = (option) => {
		return <Tag className={'mr-2 text-white px-3'} style={{ backgroundColor: option.color }} rounded value={option.display} />
	}
	const statusRowFilterTemplate = (options) => {
		const listStatus = configurationsList && configurationsList.JobOpeningStatusList ? configurationsList.JobOpeningStatusList : [];
		return <div className='flex w-10rem'>
			<MultiSelect className='flex w-full' id='statusRowFilterTemplate' value={options.value} optionLabel="display" optionValue="alias" options={listStatus} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={statusItemTemplate} selectedItemTemplate={statusSelectedTemplate} placeholder='Status wählen' selectedItemsLabel={'alle'} maxSelectedLabels={4} />
		</div>
	}
	const renderStatus = (rowData) => {
		const status = typeof rowData.status === 'object' ? rowData.status : (configurationsList.JobOpeningStatusList ? configurationsList.JobOpeningStatusList.find(entry => entry.alias === rowData.status) : {});
		return <div className="flex align-items-start text-white">
			<Tag className="text-white px-3" style={{ backgroundColor: status.color }} rounded value={status.display} />
		</div>
	}

	const createdFilterTemplate = (options) => {
		return (
			<div className='flex w-8rem filterCreatedCalendar'>
				<div className="flex flex-column flex-grow-1 justify-content-start font-normal text-sm pr-2 m-0  display-dates">
					<span className='flex justify-content-center'>{options.value && options.value[0] ? format(options.value[0], 'dd.MM.yyyy') : null}</span>
					<span className='flex justify-content-center'>{options.value && options.value[1] ? format(options.value[1], 'dd.MM.yyyy') : null}</span>
				</div>
				<div className='flex overflow-hidden' style={{ width: '38px' }}>
					<Calendar style={{ minWidth: '400px' }} showIcon iconPos='left' value={filterOpeningDate} ref={calendarRef} dateFormat="dd.mm.yy" selectionMode='range' readOnlyInput maxDate={new Date()}
						onChange={(e) => {
							const values = [e.value[0] ? getTime(e.value[0]) : null, e.value[1] ? getTime(e.value[1]) : null]
							setFilterOpeningDate(e.value)
							options.filterApplyCallback(values);
							if (e.value[1] !== null) { calendarRef.current.hide() }
						}} />
				</div>

			</div>

		)
	}

	const renderJobType = (rowData) => {
		const jobType = typeof rowData.jobType === 'object' ? rowData.jobType : (configurationsList.JobOpeningTypeList ? configurationsList.JobOpeningTypeList.find(entry => entry.alias === rowData.jobType) : {});
		return <div className="flex align-items-start">{jobType ? jobType.display : ''}</div>
	}

	const renderOpenLink = (rowData) => {
		return <div className="flex justify-content-center w-full" onClick={() => { handleSelectJobOpening(rowData.id) }}>
			<i className={ENUM_ICONS.EYE} />
		</div>
	}

	const renderOpeningDate = (rowData) => {
		return rowData.millisecondsOpeningDate ? format(rowData.millisecondsOpeningDate, 'yyyy-MM-dd') : ''
	}

	return (
		<div className='mx-auto eaa-dataView'>
			<Dialog header='Neues Stellenprofil anlegen' visible={displayCompanyDialog} onHide={() => setDisplayCompanyDialog(false)} id="dialog-jobopening-details" className='eaa-inputdialog'>
				<CompaniesProvider><JobOpeningForm editmode={true} setFormHasErrors={setFormHasErrors} setJobOpening={setJobOpening} key={'JobOpeningForm'} setShowDialog={() => { }} /></CompaniesProvider>
				<div className="flex flex-row card-container blue-container flex-row-reverse mx-4 my-2">
					<Button type="button" className="flex-order-1 button-cancel" label='Abbrechen' onClick={() => setDisplayCompanyDialog(false)} />
					<Button onClick={patchJobOpening} disabled={formHasErrors} className="flex-order-0 ml-3 " label='neu anlegen' />
				</div>
			</Dialog>
			{!jobOpening ? '' : <ReminderDialog displayDialog={displayReminderDialog} setDisplayDialog={setDisplayReminderDialog} header='Unternehmen' name={jobOpening.name} serviceId={jobOpening.id} serviceName='job-openings' userId={currentUser.id} />}
			<div className="flex justify-content-end flex-wrap card-container ">
				<div className="flex align-self-center justify-content-left  my-2">
					<Button onClick={() => { setDisplayCompanyDialog(true) }} className='flex  text-white' label='Neues Stellenprofil anlegen' />
				</div>
			</div>
			<div className="card flex md:inline-flex w-full" >
				<DataTable emptyMessage='keine Einträge gefunden' filters={filters} paginator paginatorTemplate={paginatorTemplate} first={paginationFirst} rows={paginationRows} scrollHeight={'calc(100vh - 200px)'} className='w-full' value={jobOpeningsList} showGridlines stripedRows size="small" filterDisplay='row' responsiveLayout="scroll" dataKey="id" selection={jobOpening}>
					<Column field="company.name" filter filterField='company.name' showFilterMenu={false} header="Unternehmen" filterPlaceholder='Unternehmen suchen'></Column>
					<Column className='w-15rem' field="location" filter filterField='location' showFilterMenu={false} header="Ort" filterPlaceholder='Ort suchen'></Column>
					<Column field="description" filter filterField='description' showFilterMenu={false} header="Stellenbeschreibung" filterPlaceholder='Stelle suchen'></Column>
					<Column className='w-12rem' filter field="jobType" filterElement={jobTypeRowFilterTemplate} showFilterMenu={false} header="Typ" body={renderJobType} ></Column>
					<Column className='w-5rem' showFilterMenu={false} sortField='millisecondsOpeningDate' filter filterField='millisecondsOpeningDate' filterElement={createdFilterTemplate} header="Startdatum" body={renderOpeningDate}></Column>
					<Column className='w-6rem' filter field="status" filterElement={statusRowFilterTemplate} showFilterMenu={false} header="Status" body={renderStatus}></Column>
					<Column className='open-icon justify-content-center w-3rem p-0' body={renderOpenLink} ></Column>
				</DataTable>
			</div>
		</div>
	)

}
import { Button } from "primereact/button";
import { Ripple } from "primereact/ripple";
import { useCurrentUser } from "../Persistence/CurrentUserContext"
import { NavigationEntry } from "./NavigationEntry"
import { ENUM_ROUTES } from "./Routes"
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";
import { logout } from "../feathers";
import { ErrorBoundary } from "react-error-boundary";
import { ENUM_ICONS } from "../Components/Icons";

export const Sidebar = ({ onSidebarClick, showSidebarLabel }) => {
	const currentUser = useCurrentUser();
	const configurationsList = useConfigurationsList();

	const renderMenulink = () => {
		return !configurationsList.eaaModules || !configurationsList.eaaModules.MENULINK ? '' :
			configurationsList.eaaModules.MENULINK.map((link) => {
				return <NavigationEntry key={'link-' + link.key} icon={link.icon} label={link.title} route={ENUM_ROUTES.DASHBOARD} onSidebarClick={() => { window.open(link.url, '_blank') }} showLabel={showSidebarLabel} />
			})
	}
	function ErrorFallback({ error, resetErrorBoundary }) {
		return (
			<div role="alert">
				<p>Something went wrong:</p>
				<pre>{error.message}</pre>
				<button onClick={resetErrorBoundary}>Try again</button>
			</div>
		)
	}
	return (
		<div className="flex flex-column h-full" >
			<div className="flex pl-2 pt-3" style={{ height: '60px' }}>
				<img src="../icons/Logo_EAA-Tool.png" style={{ height: '30px' }} />
			</div>
			<div className="mt-3">
				<ul className="list-none p-0 m-0">
					<NavigationEntry icon={ENUM_ICONS.DASHBOARD} label='Dashboard' route={ENUM_ROUTES.DASHBOARD} onSidebarClick={onSidebarClick} showLabel={showSidebarLabel} />
					<NavigationEntry icon={ENUM_ICONS.EAACASE} label='Fallverwaltung' route={ENUM_ROUTES.EAACASES} onSidebarClick={onSidebarClick} showLabel={showSidebarLabel} />
					<NavigationEntry icon={ENUM_ICONS.COMPANY} label='Unternehmen' route={ENUM_ROUTES.COMPANIES} onSidebarClick={onSidebarClick} showLabel={showSidebarLabel} />
					<NavigationEntry icon={ENUM_ICONS.STATISTICS} label='Statistik' route={ENUM_ROUTES.STATISTICS} onSidebarClick={onSidebarClick} showLabel={showSidebarLabel} />
					{configurationsList.eaaModules && configurationsList.eaaModules.PRACTIVITY !== '1' ? '' :
						<NavigationEntry icon={ENUM_ICONS.PROVIDERACTIVITIES} label='Trägeraktivitäten' route={ENUM_ROUTES.PROVIDERACTIVITIES} onSidebarClick={onSidebarClick} showLabel={showSidebarLabel} />
					}
					{configurationsList.eaaModules && configurationsList.eaaModules.JOBOPENING !== '1' ? '' :
						<NavigationEntry icon={ENUM_ICONS.JOBPROFILE} label='Stellenprofile' route={ENUM_ROUTES.JOBPROFILES} onSidebarClick={onSidebarClick} showLabel={showSidebarLabel} />
					}
					{currentUser.permissions !== 'ADMIN' ? '' :
						<>
							<NavigationEntry icon={ENUM_ICONS.USERADMINISTRATION} label='Nutzerverwaltung' route={ENUM_ROUTES.USERADMINISTRATION} onSidebarClick={onSidebarClick} showLabel={showSidebarLabel} />
							<NavigationEntry icon={ENUM_ICONS.SETTINGS} label='Einstellungen' route={ENUM_ROUTES.SETTINGS} onSidebarClick={onSidebarClick} showLabel={showSidebarLabel} />
						</>}
					<ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => {
						// reset the state of your app
					}}>
						{renderMenulink()}
					</ErrorBoundary>
				</ul>
			</div>


			<div className="mt-auto">
				<hr className="mx-3 border-top-1 border-none surface-border" />
				<div className="hidden flex-row mt-3 justify-content-center">
					<Button onClick={() => { onSidebarClick(ENUM_ROUTES.USERPROFILE, ENUM_ICONS.USER, 'Benutzerprofil') }} icon={ENUM_ICONS.USER} className="p-button-rounded p-button-outlined color-lessblack" />
				</div>
				<a className="p-ripple my-3 gap-2 flex flex-row align-items-center cursor-pointer p-3 lg:justify-content-start text-600 border-left-2 border-transparent hover:border-300 transition-duration-150 transition-colors" onClick={logout}>
					<i className="flex fa fa-sign-out mr-2 lg:mr-0 text-base lg:text-2xl text-pink-500"></i>
					<span className={(showSidebarLabel ? 'flex inline' : 'hidden') + " font-medium"}>Logout</span>
					<Ripple />
				</a>
			</div>

		</div>
	)

}

import { useEffect, useState } from "react";
import { useRegionsList, useRegionsSelectionList } from "../Persistence/RegionsContext";
import { InputChangesChecked } from "../Components/InputChangesChecked";
import { useFormik } from 'formik';
import { useCompaniesList } from "../Persistence/CompaniesContext";
import { AutoCompleteChangesChecked } from "../Components/AutoCompleteChangesChecked";
import { DropdownChangesChecked } from "../Components/DropdownChangesChecked";
import { InputNumberChecked } from "../Components/InputNumberChecked";
import { ENUM_ICONS } from "../Components/Icons";

export const employmentObligationOptions = [{ display: 'Ja', value: 1 }, { display: 'Nein', value: 0 }, { display: 'berechnet', value: 2 }]
export const emptyCompany = { id: null, version: 1, active: 1, name: '', phone: '', fax: '', mail: '', employees: '', employeesSbM: '', chamber: '', chamberNo: '', regionId: '', region: '', addresses: [], contactPersons: [], updateUser: null, companyUsers: [], companyComments: null }

export const CompanyForm = ({ company, setCompanyForm, setFormHasErrors, handleSelectExistingCompany, showTrash, setShowDialog, editmode, createEAA }) => {

	const regionsList = useRegionsList();
	const regionsSelectionList = useRegionsSelectionList();
	const useCompanyList = useCompaniesList();

	const [regionsSelection, setRegionsSelection] = useState([]);
	const [companiesList, setCompaniesList] = useState([])

	useEffect(() => {
		if (useCompanyList) {
			setCompaniesList(useCompanyList.filter((entry) => entry.active))
		}
	}, [useCompanyList])

	const formik = useFormik({
		initialValues: {
			id: null, version: 1, active: 1, name: '', phone: '', fax: '', mail: '', employees: 0, employeesSbM: 0, chamber: '', chamberNo: '', employmentObligation: 2, regionId: '', region: '', addresses: [], contactPersons: [], updateUser: null, companyUsers: [], companyComments: null
		},
		validate: (data) => {
			let errors = {};

			if (!data.name) {
				errors.name = 'Geben Sie den Namen des Unternehmens ein. Wenn dieses Unternehmen bereits in der Datenbank existiert, können Sie es auswählen.<br/>Wenn das Unternehmen noch nicht in der Datenbank existiert, können Sie die Informationen eintragen und speichern.';
			}
			if (!data.region) {
				errors.region = 'Region  ist ein Pflichtfeld';
			} else {
				data.regionId = data.region.id
			}
			if (!data.employees === '0' && !data.employees) {
				errors.employees = 'Mitarbeiter*innen ist ein Pflichtfeld';
			}
			if (!data.employeesSbM === '0' && !data.employeesSbM) {
				errors.employeesSbM = 'SbM ist ein Pflichtfeld';
			} else if (data.employees && data.employeesSbM > data.employees) {
				errors.employeesSbM = 'SbM muss kleiner als Anzahl Mitarbeiter*innen sein!';
			}
			const hasErrors = Object.keys(errors).length !== 0 ? data : null
			setFormHasErrors(hasErrors);
			if (!hasErrors) {
				setCompanyForm(data);
			}
			return errors;
		},

		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,

	});

	const createRegionSelection = () => {
		const regionSelection = regionsSelectionList ? [...regionsSelectionList] : [];
		if (company) {
			const region = regionsList.find(entry => entry.id === company.regionId)
			if (region && !region.active) {
				regionSelection.push(region);
			}
		}
		setRegionsSelection(regionSelection)
	}

	useEffect(() => {
		console.log('companyForm useEffect')
		createRegionSelection();
		if (company) {
			const region = regionsList.find(entry => entry.id === company.regionId)
			if (region) {
				company.region = region
			}
			formik.setValues(company);
		} else {
			formik.setValues({ ...emptyCompany })
		}
	}, [company, regionsSelectionList])

	const handleCompanyNameChange = (e) => {
		if (e.value && typeof e.value === 'object' && e.value.constructor === Object) {
			handleSelectExistingCompany(e.value.id)
		} else {
			formik.setFieldValue('name', e.value)
		}
	}

	return (
		<div>{!formik ? <div></div> :

			< div className='col' >
				<form onSubmit={formik.handleSubmit}>
					<div className="formgrid grid">
						{formik.values && !formik.values.id ? <>
							<AutoCompleteChangesChecked dropdown={true} label={'Name des Unternehmens'} id={'name'} itemLabel={'name'} formik={formik} forceSelection={false} suggestionsList={companiesList}
								helptextcolor='#F4A261' helptext='Ein Unternehmen mit diesem Namen ist der Datenbank nicht bekannt. Beim Speichern wird das Unternehmen mit den eingegebenen Daten in der Datenbank eingetragen.'
								setShowDialog={setShowDialog} handleChange={handleCompanyNameChange} />
						</> : <>
							<InputChangesChecked id='name' type="text" disabled={!editmode} formik={formik} value={formik.values.name} setValue={formik.handleChange}
								helptextcolor='#24A347' helptext='Sie haben ein bekanntes Unternehmen aus der Datenbank ausgewählt. Es wird kein neues Unternehmen erstellt.'
								label='Name des Unternehmens' setShowDialog={setShowDialog} />
						</>}
						{showTrash && (formik.values && formik.values.id) ? <div className="field col mr-3">
							<div className="flex flex-row flex-row-reverse"><i className={ENUM_ICONS.TRASH} onClick={() => { handleSelectExistingCompany(null); formik.resetForm(); }}></i></div>
						</div> : ''}

					</div>
					<div className="formgrid grid">
						<InputChangesChecked id='phone' type="text" formik={formik} label='Telefon' setShowDialog={setShowDialog} disabled={!editmode} />
						<InputChangesChecked id='fax' type="text" formik={formik} label='Fax' setShowDialog={setShowDialog} disabled={!editmode} />
					</div>
					<div className="formgrid grid">
						<InputChangesChecked id='mail' label='E-Mail' type="text" formik={formik} setShowDialog={setShowDialog} disabled={!editmode} />
					</div>

					<div className="formgrid grid">
						<InputNumberChecked className='w-5rem' id='employees' label='Mitarbeiter*innen' type="number" formik={formik} setShowDialog={setShowDialog} disabled={!editmode} min={0} showButtons={true} />
						<InputNumberChecked className='w-5rem' id='employeesSbM' label='SbM' type="number" formik={formik} setShowDialog={setShowDialog} disabled={!editmode} min={0} showButtons={true} />
					</div>
					<div className="formgrid grid">
						<InputChangesChecked id='chamber' label='Kammer' type="text" formik={formik} setShowDialog={setShowDialog} disabled={!editmode} />
						<InputChangesChecked id='chamberNo' label='Betriebsnummer' type="text" formik={formik} setShowDialog={setShowDialog} disabled={!editmode} />
					</div>
					<div className="formgrid grid">
						<DropdownChangesChecked options={regionsSelection} editmode={editmode} formik={formik} id={'region'} itemLabel={'name'} label={'EAA Region'} onChange={createRegionSelection} setShowDialog={setShowDialog} />
						<DropdownChangesChecked options={employmentObligationOptions} editmode={editmode} formik={formik} id={'employmentObligation'} itemLabel={'display'} label={'Beschäftigungspflicht'} setShowDialog={setShowDialog} />
					</div>
				</form>
			</div>
		}</div>


	)
}

import { format, parseISO } from "date-fns";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { classNames } from "primereact/utils";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { CaseEventsSheet } from "../CaseEvents/CaseEventsSheet";
import { CasePersonForm } from "../CasePersons/CasePersonForm";
import { CasePersonSheet } from "../CasePersons/CasePersonSheet";
import { CaseUsersSheet } from "../CaseUsers/CaseUsersSheet";
import { CompanyChangeDialog } from "../Companies/CompanyChangeDialog";
import { CompanySheet } from "../Companies/CompanySheet";
import { FieldInfo } from "../Components/FieldInfo";
import { OverlayPanelMenu } from "../Components/OverlayPanelMenu";
import { fetchIconsBase64, getData, patchData } from "../feathers";
import { CasePersonsProvider } from "../Persistence/CasePersonsContext";
import { useCurrentUser } from "../Persistence/CurrentUserContext";
import { ReminderDialog } from "../Reminders/ReminderDialog";
import { EAACaseForm } from "./EAACaseForm";
import { ToastError } from "../Utils/ToastError";
import { ConfirmDialog } from "../Components/ConfirmDialog";
import { ENUM_ICONS } from "../Components/Icons";
import exportToPDF from "../Utils/exportToPDF";
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";

const sortCaseUser = (eaaCase) => {
	eaaCase.caseUsers.sort((b, a) => {
		if (a.roleType.alias < b.roleType.alias) {
			return -1;
		}
		if (a.roleType.alias > b.roleType.alias) {
			return 1;
		}
		return 0;
	})
}

export const EAACaseSheet = () => {

	const currentUser = useCurrentUser();
	const configurationsList = useConfigurationsList();
	const { eaaCaseId } = useParams();
	const [eaaCase, setEaaCase] = useState();
	const [casePerson, setCasePerson] = useState();
	const [active, setActive] = useState(0);
	const [readonly, setReadonly] = useState(true);
	const [displayReminderDialog, setDisplayReminderDialog] = useState(false);
	const [displayCaseUserDialog, setDisplayCaseUserDialog] = useState(false);
	const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
	const [displayCompanyDialog, setDisplayCompanyDialog] = useState(false);
	const [displayEaaCaseDialog, setDisplayEAACaseDialog] = useState(false);
	const [displayCasePersonDialog, setDisplayCasePersonDialog] = useState(false);
	const [toastParams, setToastParams] = useState(null);

	useEffect(() => {
		if (eaaCaseId) {
			getData("eaa-cases", eaaCaseId).then((eaaCase) => {
				sortCaseUser(eaaCase);
				console.log('eaaCase ', eaaCase);
				setReadonly(eaaCase.status.alias === 'INACTIVE');
				setEaaCase(eaaCase);
			});
		}
	}, [eaaCaseId])

	const eaaCaseUpdate = async () => {
		await getData("eaa-cases", eaaCase.id).then((eaaCase) => {
			//console.log('eaaCaseUpdate readonly: ' + !eaaCase.active, eaaCase)
			setReadonly(eaaCase.status.alias === 'INACTIVE');
			sortCaseUser(eaaCase);
			setEaaCase(eaaCase);
		}).catch((error) => {
			setToastParams({ title: 'Daten konnten nicht ermittelt werden', message: error.message })
		});
	}

	const patchEaaCase = async (eaaCase) => {
		await patchData('eaa-cases', eaaCase).then(async (eaaCase) => {
			await eaaCaseUpdate();
		}).catch((error) => {
			setToastParams({ title: 'Daten konnten nicht gespeichert werden', message: error.message })
		})
	}
	const patchCasePerson = async () => {
		await patchData('case-persons', casePerson).then(async (casePerson) => {
			eaaCase.casePersonId = casePerson.id
			await patchEaaCase(eaaCase)
			setDisplayCasePersonDialog(false)
		}).catch((error) => {
			setToastParams({ title: 'Fallperson konnte nicht ' + (casePerson.id ? 'geändert' : 'angelegt') + ' werden', message: error.message })
		})
	}

	const renderDocLink = () => {
		let docLink = '';
		if (eaaCase && eaaCase.documentLocation) {
			docLink = 'file:///' + eaaCase.documentLocation;
			try {
				const url = new URL(eaaCase.documentLocation);
				if (url.protocol === "http:" || url.protocol === "https:") {
					docLink = eaaCase.documentLocation
				}
			} catch (_) { }
		}
		return <a href={docLink} target="_blank">{docLink}</a>

	}

	const renderCaseSheet = () => {
		const maxChar = 50;
		const title = eaaCase.company.name + eaaCase.name;
		return <div className="eaa-maincontent flex flex-column " >
			<div className='flex justify-content-end mb-4 gap-2'>
				<Button onClick={handleUseForReminder} icon={ENUM_ICONS.CLOCK} className='flex text-white' style={{ backgroundColor: '#494C4F' }} label='Wiedervorlage' />
			</div>
			<div className="flex justify-content-center mt-0 text-4xl font-semibold border-none h-3rem mx-8">
				{eaaCase ? `${eaaCase.company.name} - ${(title.length > maxChar ? eaaCase.name.substring(0, maxChar - eaaCase.company.name.length) + '...' : eaaCase.name)}` : ''}
			</div>
			<div className='flex text-lg justify-content-center my-2 gap-2'>
				<div>{eaaCase ? `${eaaCase.caseDocId} vom ${format(parseISO(eaaCase.date), 'dd.MM.yyyy')}` : ''}</div>
				<Tag className={'mr-2 text-white px-3'} style={{ backgroundColor: eaaCase.status.color }} rounded value={eaaCase.status.display} />
			</div>
			<div className='flex flex-column my-4' >
				<div className='flex flex-wrap justify-content-between w-full'>
					<FieldInfo label='Unternehmen' content={eaaCase.company.name} />
					<FieldInfo label='Verantwortliche*r' content={eaaCase.responsible ? eaaCase.responsible.displayname : 'n.n.'} />
					<FieldInfo label='Ersteller*In' content={eaaCase.caseCreator.displayname} />
				</div>
				<div className='flex flex-wrap justify-content-between'>
					<FieldInfo label='Initiiert durch' content={eaaCase.initiatedBy.display} />
					<FieldInfo label='Dateipfad Dokumente' content={renderDocLink()} />
				</div>
				<Divider />

				<div className='flex flex-column surface-ground p-2'>
					<div className='flex text-600 text-base mb-1'>
						Beschreibung
					</div>
					<div className='editor-changes-checked mx-4' dangerouslySetInnerHTML={{ __html: eaaCase.description }} />
				</div>

				<div className='flex text-1xl m-2'>
					{eaaCase ? <CaseEventsSheet eaaCase={eaaCase} caseEvents={eaaCase.caseEvents} eaaCaseUpdate={eaaCaseUpdate} /> : ''}
				</div>

				<ReminderDialog displayDialog={displayReminderDialog} setDisplayDialog={setDisplayReminderDialog} header='Fall' name={eaaCase.name + ' - ' + eaaCase.company.name} serviceId={eaaCase.id} serviceName='eaa-cases' userId={currentUser.id} />
				<Dialog header='Falldetails ändern' visible={displayEaaCaseDialog} onHide={() => setDisplayEAACaseDialog(false)} id="dialog-eaacase" className='eaa-inputdialog' >
					<div className='flex font-light mb-3 p-0 '>{'Hier können Sie die Details eines Falles ändern.'}</div>
					<EAACaseForm eaaCase={eaaCase} patchEaaCase={patchEaaCase} setDisplayEAACaseDialog={setDisplayEAACaseDialog} />
				</Dialog>
				<Dialog header='Details zur Fallperson ändern' visible={displayCasePersonDialog} onHide={() => setDisplayCasePersonDialog(false)} id="dialog-caseperson" className='eaa-inputdialog'>
					<div className='flex font-light mb-3 p-0 '>{casePerson && casePerson.personId ? 'Hier können Sie die Details der Person ' + casePerson.personId + ' ändern.' : 'Hier können Sie eine neue Person zum Fall auswählen oder erstellen'}</div>
					<CasePersonsProvider><CasePersonForm eaaCase={eaaCase} handleCasePersonUpdate={setCasePerson} setShowDialog={() => { }} /></CasePersonsProvider>
					<div className="flex flex-row card-container justify-content-end gap-2 mx-4 my-2">
						<Button className="button-cancel" label="Abbrechen" onClick={() => { setDisplayCasePersonDialog(false) }} />
						<Button onClick={patchCasePerson} disabled={false} className="flex-order-0 ml-3 " label={eaaCase.casePerson && eaaCase.casePerson.id ? 'Ändern' : 'Neu hinzufügen'} />
					</div>

				</Dialog>
				<CompanyChangeDialog companyId={eaaCase.companyId} displayCompanyDialog={displayCompanyDialog} setDisplayCompanyDialog={setDisplayCompanyDialog} handleOnCompanyPatched={eaaCaseUpdate} />

			</div>
		</div>
	}

	const handleUseForReminder = async () => {
		setDisplayReminderDialog(true)
	}
	const handleEditEAACase = () => {
		setDisplayEAACaseDialog(true)
	}
	const handleExportEAACase = async () => {
		await exportToPDF('EAACASE', eaaCase, JSON.parse(JSON.stringify(configurationsList.CaseEventClassifierStructure)));

	}
	const handleStatusEAACase = async () => {
		eaaCase.status = { alias: (eaaCase.status.alias === 'INACTIVE' ? 'OPEN' : 'INACTIVE') }
		await patchEaaCase(eaaCase);
	}
	const handleEditCaseUsers = () => {
		setDisplayCaseUserDialog(true)
	}

	return (

		<div className="flex m-0 w-full h-full select-none">
			<ConfirmDialog title='Status ändern' message={'Fall' + (readonly ? ' aktivieren?' : ' inaktivieren?')} labelOk='Ja'
				handleOnClick={handleStatusEAACase} displayConfirmDialog={displayConfirmDialog} setDisplayConfirmDialog={setDisplayConfirmDialog} />

			<ToastError toastParams={toastParams} />
			<div className="flex w-full h-full overflow-y-auto">
				<div className='flex w-full justify-content-center eaa-dataView mt-0' style={{ minWidth: '400px' }}>
					{!eaaCase ? '' : renderCaseSheet()}
				</div>
			</div>
			<div className="flex bg-white eaa-sidecontent h-full" >
				<div className="flex flex-column eaa-sidecontent pt-2" >
					<div className='grid m-0 p-0'>
						<div className="col">
							<ul className="card-container pl-3 m-0 list-none flex overflow-x-auto select-none justify-content-start">
								<li>
									<a className={classNames('p-ripple cursor-pointer px-4 py-2 flex align-items-center border-bottom-2 hover:border-500 transition-colors transition-duration-150 border-noround', { 'border-blue-500 text-blue-500 hover:border-blue-500': active === 0, 'text-700 border-transparent': active !== 0 })} onClick={() => setActive(0)}>
										<i className={ENUM_ICONS.COMPANY + "fa-lg mr-2"}></i>
									</a>
								</li>
								<li>
									<a className={classNames('p-ripple cursor-pointer px-4 py-2 flex align-items-center border-bottom-2 hover:border-500 transition-colors transition-duration-150 border-noround', { 'border-blue-500 text-blue-500 hover:border-blue-500': active === 1, 'text-700 border-transparent': active !== 1 })} onClick={() => setActive(1)}>
										<i className={ENUM_ICONS.USER + "fa-lg mr-2"}></i>
									</a>
								</li>
								<li>
									<a className={classNames('p-ripple cursor-pointer px-4 py-2 flex align-items-center border-bottom-2 hover:border-500 transition-colors transition-duration-150 border-noround', { 'border-blue-500 text-blue-500 hover:border-blue-500': active === 2, 'text-700 border-transparent': active !== 2 })} onClick={() => setActive(2)}>
										<i className={ENUM_ICONS.CASE_USER + "fa-lg mr-2"}></i>
									</a>
								</li>
							</ul>
						</div>
						<div className="col-fixed w-2rem flex  align-content-center">
							<div className='flex align-items-center w-full'>
								<OverlayPanelMenu items={[{ label: 'Fall ändern', icon: ENUM_ICONS.EAACASE, command: handleEditEAACase, disabled: currentUser.permissions === 'READER' || readonly },
								{ label: 'Exportieren', disabled: false, icon: ENUM_ICONS.EXPORT, command: handleExportEAACase },
								{ separator: true },
								{ label: (readonly ? 'Aktiv setzen' : 'Inaktiv setzen'), icon: (readonly ? ENUM_ICONS.REACTIVATE : ENUM_ICONS.TRASH), command: () => setDisplayConfirmDialog(true), disabled: currentUser.permissions === 'READER' }]} />
							</div>
						</div>
					</div>
					<div className="col-fixed eaa-sidecontent">
						{eaaCase ?
							<>
								{active === 0 ? <CompanySheet readonly={readonly} company={eaaCase.company} onUpdateCompany={eaaCaseUpdate} /> : ''}
								{active === 1 ? <CasePersonSheet eaaCase={eaaCase} patchEaaCase={patchEaaCase} /> : ''}
								{active === 2 ? <CaseUsersSheet eaaCase={eaaCase} hideDivider={true} handleOnCaseUserPatched={eaaCaseUpdate} handleCaseUserEdit={handleEditCaseUsers} /> : ''}
							</>
							: ''}
					</div>

				</div>
			</div>
		</div>


	)
}

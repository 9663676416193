import { SplitButton } from "primereact/splitbutton";
import { Divider } from "primereact/divider";
import { useCurrentUser } from "../Persistence/CurrentUserContext";

export const CaseEventDivider = ({ icon, label, onClickHandler, className }) => {
	const currentUser = useCurrentUser();
	const items = [
		{ label: 'Neue interne Notiz', command: () => { onClickHandler('INTERN') } },
		{ label: 'Neues Ergebnis', command: () => { onClickHandler('CONTACTRESULT') } },
		{ label: 'Neue Nachhaltigkeit', command: () => { onClickHandler('SUSTAINABILITY') } },
	]

	return (
		<Divider className={"caseevent-divider " + (className ? className : ' h-1rem')} align="center">
			<SplitButton className='m-0 p-0' label={label} icon={icon + " mr-3"} onClick={() => onClickHandler('CONTACT')} model={items} disabled={currentUser.permissions === 'READER'} ></SplitButton>
		</Divider>

	)
}
import { useFormik } from 'formik';
import { Button } from "primereact/button";
import { Dropdown } from 'primereact/dropdown';
import { InputText } from "primereact/inputtext";
import { classNames } from 'primereact/utils';
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import UnsafedChangesDialog from "../Components/UnsafedChangesDialog";
import { getData, patchData } from "../feathers";
import { useBreadcrumbsAdd } from '../Persistence/Breadcrumbs';
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";
import { useCurrentUser } from "../Persistence/CurrentUserContext";
import logger from "../Utils/logger";
import { useCallbackPrompt } from "../Utils/useCallbackPrompt";


export const AddressesForm = ({ addressId, handleSave, handleCancel }) => {
	const currentUser = useCurrentUser();
	const configurationsList = useConfigurationsList();
	const [filteredAddressTypes, setFilteredAddressTypes] = useState(null);
	const [address, setAddress] = useState();
	const [editmode, setEditmode] = useState(true);
	const [hasErrors, setHasErrors] = useState(false);
	const [showDialog, setShowDialog] = useState(false);
	const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog);

	const addBreadcrumb = useBreadcrumbsAdd();
	const location = useLocation();

	const patchAddress = async (address) => {
		const { id } = address
		await patchData('addresses', address, currentUser).then((address) => {
			/** 
			 * Wenn die Adresse erfolgreich angelegt ist -> evtl Assoziationen zu Companies oder ContactPersons aktualisieren 
			*/
			setAddress(address);
			formik.setValues(address);
			setShowDialog(false); // Prüfung ob Felder geändert wurde 
			setEditmode(false)

			if (handleSave) {
				/* Wenn AddressForm eingebettet ist und ein handleSave übergeben wurde, dann über die handleSave das Parent informieren*/
				handleSave(address.id)
			} else if (addressId === 'new') {
				/* AddressForm wurde über Route aufgerufen und nach dem Speichern
				soll die Route auf die neue Adresse navigieren*/
				const route = location.pathname.replace('new', '') + address.id
				addBreadcrumb(route)
			}
		}).catch((error) => {
			logger.error('Fehler beim Speichern einer Adresse: ' + error.message)
		});
	}

	useEffect(() => {
		if (addressId && addressId !== null) {
			getData("addresses", addressId).then((address) => {
				setAddress(address);
				formik.setValues(address);
			});
		} else {
			setEditmode(true)
		}
	}, [addressId])

	const formik = useFormik({
		initialValues: {
			id: null, version: 1, line1: '', line2: '', line3: '', city: '', postcode: '', country: '', addressType: null, companies: [], contactPersons: []
		},
		validate: (data) => {
			let errors = {};
			if (!data.line1) {
				errors.line1 = 'Strasse / Hausnummer müssen angegeben werden';
			}
			if (!data.postcode) {
				errors.postcode = 'Postleitzahl';
			}
			if (!data.city) {
				errors.city = 'Ort ist ein Pflichtfeld';
			}
			if (!data.country) {
				errors.country = 'Land ist ein Pflichtfeld';
			}
			if (!data.addressType) {
				errors.addressType = 'Adresstyp  ist ein Pflichtfeld';
			}
			setHasErrors(Object.keys(errors).length !== 0)
			return errors;
		},
		onSubmit: (data) => {
			patchAddress(data)
		},
		enableReinitialze: true,
		validateOnMount: true
	});

	const isFormFieldValid = (name) => { return formik.errors[name] };
	const getFormErrorMessage = (name) => {
		return isFormFieldValid(name) && <small className="p-error block">{formik.errors[name]}</small>;
	};

	const cancelEditmode = async () => {
		if (handleCancel) {
			handleCancel()
		} else {
			setEditmode(false)
			formik.setValues(address)
		}
	}

	const renderButtons = () => {
		return <div><div className={editmode ? 'card' : 'hidden'}>
			<div className="flex flex-row card-container blue-container flex-row-reverse">
				<Button type='button' className="flex-order-1 button-cancel" label='Abbrechen' onClick={cancelEditmode} />
				<Button className="flex-order-0 ml-3" disabled={hasErrors} label={addressId !== 'new' ? 'Speichern' : 'Neu anlegen'} type='submit' onClick={cancelEditmode} />
			</div>
		</div>
			<div className={!editmode ? 'card' : 'hidden'}>
				<div className="flex flex-row card-container blue-container flex-row-reverse">
					<Button className="flex-order-0 ml-3" label='bearbeiten' onClick={(e) => { setEditmode(true) }} type='button' />
				</div>
			</div></div>
	}

	return (
		<div className='grid'>{!formik ? <div></div> :

			<div className='col'>
				< form onSubmit={formik.handleSubmit} >
					<UnsafedChangesDialog
						// @ts-ignore
						showDialog={showPrompt}
						confirmNavigation={confirmNavigation}
						cancelNavigation={cancelNavigation}
					/>
					<div className="formgrid grid  m-0 p-0">
						<div className="field col mr-3">
							<label htmlFor="addressType" className={classNames({ 'p-error': isFormFieldValid('addressType') }) + ' block'}>Adresstyp</label>
							<Dropdown id="addressType" name="addressType" optionLabel="display" disabled={!editmode} value={formik.values.addressType} options={configurationsList.AddressTypeList} onChange={formik.handleChange} autoFocus className={'bg-white w-full'} />
							{getFormErrorMessage('addressType')}
						</div>
					</div>
					<div className="formgrid grid  m-0 p-0">
						<div className="field col mr-3">
							<label htmlFor="line1" className={classNames({ 'p-error': isFormFieldValid('line1') }) + ' block'}>Adresslinie 1</label>
							<InputText id="line1" name="line1" value={formik.values.line1} disabled={!editmode} onChange={(e) => { setShowDialog(true); formik.handleChange(e) }} className={classNames({ 'p-invalid': isFormFieldValid('line1') }) + ' w-full'} />
							{getFormErrorMessage('line1')}
						</div>
					</div>
					<div className="formgrid grid  m-0 p-0">
						<div className="field col mr-3">
							<label htmlFor="line2" className='block'>Adresslinie 2</label>
							<InputText id="line2" name="line2" value={formik.values.line2} disabled={!editmode} onChange={(e) => { setShowDialog(true); formik.handleChange(e) }} className=' w-full' />
						</div>
					</div>
					<div className="formgrid grid  m-0 p-0">
						<div className="field col mr-3">
							<label htmlFor="line3" className='block'>Adresslinie 3</label>
							<InputText id="line3" name="line3" value={formik.values.line3} disabled={!editmode} onChange={(e) => { setShowDialog(true); formik.handleChange(e) }} className=' w-full' />
						</div>
					</div>
					<div className="formgrid grid  m-0 p-0">
						<div className="field col-fixed mr-3 w-5rem" >
							<label htmlFor="postcode" className={classNames({ 'p-error': isFormFieldValid('postcode') }) + ' block'}>PLZ</label>
							<InputText id="postcode" name="postcode" value={formik.values.postcode} disabled={!editmode} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('postcode') }) + ' w-5rem'} />
							{getFormErrorMessage('postcode')}
						</div>
						<div className="field col mr-3 ">
							<label htmlFor="city" className={classNames({ 'p-error': isFormFieldValid('city') }) + ' block'}>Ort</label>
							<InputText id="city" name="city" value={formik.values.city} disabled={!editmode} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('city') }) + ' w-full'} />
							{getFormErrorMessage('city')}
						</div>
					</div>
					<div className="formgrid grid  m-0 p-0">
						<div className="field col  mr-3">
							<label htmlFor="country" className={classNames({ 'p-error': isFormFieldValid('country') }) + ' block'}>Land</label>
							<InputText id="country" name="country" value={formik.values.country} disabled={!editmode} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('country') }) + ' w-full'} />
							{getFormErrorMessage('country')}
						</div>
					</div>
					{renderButtons()}
				</form>
			</div>
		}</div>
	)

}
import { parseISO } from "date-fns";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { useEffect, useState } from "react";
import { CalendarChangesChecked } from "../Components/CalendarChangesChecked";
import { DropdownChangesChecked } from "../Components/DropdownChangesChecked";
import { EditorChangesChecked } from "../Components/EditorChangesChecked";
import { InputChangesChecked } from "../Components/InputChangesChecked";
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";


export const EAACaseForm = ({ eaaCase, patchEaaCase, setDisplayEAACaseDialog }) => {
	const [hasFormErrors, setHasFormErrors] = useState(false)
	const configurationsList = useConfigurationsList();

	const formik = useFormik({
		initialValues: {
			id: null, version: 1, active: 1, caseDocId: '', name: '', description: eaaCase ? eaaCase.description : '', date: null, initiatedBy: '', status: '', documentLocation: '', supportCaseId: ''
		},
		validate: (data) => {
			let errors = {};

			if (!data.name) {
				errors.name = 'Name ist ein Pflichtfeld';
			}
			if (!data.status) {
				errors.status = 'Status ist ein Pflichtfeld';
			}
			if (!data.initiatedBy) {
				errors.initiatedBy = 'Initiiert ist ein Pflichtfeld';
			}
			if (!data.date) {
				errors.date = 'Datum ist ein Pflichtfeld';
			}
			if (!data.description) {
				errors.description = 'Beschreibung ist ein Pflichtfeld';
			}
			setHasFormErrors(Object.keys(errors).length !== 0)
			return errors;
		},
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	useEffect(() => {
		const _eaaCase = { ...eaaCase }
		_eaaCase.date = parseISO(_eaaCase.date)
		formik.setValues(_eaaCase)
	}, [eaaCase])

	return (
		<>
			<div className="formgrid grid">
				<InputChangesChecked id='name' type="text" formik={formik} editmode={true} label='Kurzbeschreibung / Titel' setShowDialog={() => { }} />
			</div>
			<div className="formgrid grid">
				<CalendarChangesChecked id='date' formik={formik} editmode={true} label='Datum' setShowDialog={() => { }} maxDate={new Date()} />
				<div className="field col mr-3" />
			</div>
			<div className="formgrid grid">
				<DropdownChangesChecked tabIndex={102} editmode={true} label={'Status'} id={'status'} itemLabel={'display'} formik={formik} options={configurationsList.CaseStatusList} setShowDialog={() => { }} handleChange={formik.handleChange} />
				<DropdownChangesChecked editmode={true} label={'Inititiert'} id={'initiatedBy'} itemLabel={'display'} formik={formik} options={configurationsList.InitiatedByTypesList} setShowDialog={() => { }} handleChange={formik.handleChange} />
			</div>
			<div className="formgrid grid">
				<InputChangesChecked id='documentLocation' label='Dateipfad für Dokumentation' type="text" formik={formik} editmode={true} setShowDialog={() => { }} />
			</div>
			<div className={"formgrid grid"}>
				<EditorChangesChecked id='description' formik={formik} value={formik.values.description} setValue={(value) => formik.setFieldValue('description', value)} label='Beschreibung' setShowDialog={() => { }} />
			</div>
			<div className={'card'}>
				<div className="flex flex-row card-container blue-container flex-row-reverse mx-4 my-2">
					<Button onClick={async () => { setDisplayEAACaseDialog(false); patchEaaCase(formik.values); }} disabled={hasFormErrors} className="flex-order-0 ml-3 " label={'Speichern'} />
					<Button className='button-cancel' label="Abbrechen" onClick={() => { setDisplayEAACaseDialog(false) }} />
				</div>
			</div>
		</>

	)
}
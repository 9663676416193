import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { fetchIconsBase64, getData } from "../feathers";
import { format, parseISO } from "date-fns";
//eslint-disable-next-line @typescript-eslint/no-var-requires

const fontSize_XS = 8;
const fontSize_S = 10;
const fontSize = 12;
const fontSize_M = 14;
const fontSize_L = 16;

const htmlToPdfmake = require("html-to-pdfmake");

const defaultStyles = { // change the default styles
	h1: { fontSize: fontSize_M },
	h2: { fontSize: fontSize },
	p: { margin: [0, 1, 0, 1] },
	ol: { marginBottom: 5 },
	li: { marginLeft: 30 },
}
const styles = {
	headerdate: { fontSize: fontSize_XS },
	tableBody: { fontSize: fontSize_S },
	smallerText: { fontSize: fontSize_S },
	normalText: { fontSize: fontSize },
	normalTextBold: { fontSize: fontSize, bold: true },
	headline: {
		bold: true,
		fontSize: fontSize,
		color: 'black',
		margin: [0, 10, 0, 5]
	},
};
const horizontalLine = {
	margin: [0, 10, 0, 10],
	layout: { hLineWidth: function (i, node) { return i == 0 ? 1 : 0 }, vLineWidth: function (i, node) { return 0 } },
	table: { widths: ['*'], body: [['']] }
}
const tableLayoutCard = {
	hLineWidth: function (i, node) { return 0 }, vLineWidth: function (i, node) { return 0 },
	fillColor: function (rowIndex, node, columnIndex) { return (columnIndex === 1 || (columnIndex === 2 && node.table.body[0][2].text === '') ? '#ffffff' : '#eeeeee') },
	paddingLeft: function (i, node) { return 5; }, paddingRight: function (i, node) { return 5; },
	paddingTop: function (i, node) { return 5 }, paddingBottom: function (i, node) { return 5 }
}
const tableLayoutCaseEventHeadline = {
	vLineWidth: function (i, node) { return (i === 0 || i === node.table.widths.length) ? 1 : 0 },
	paddingLeft: function (i, node) { return 5; }, paddingRight: function (i, node) { return 5; },
	paddingTop: function (i, node) { return 5 }, paddingBottom: function (i, node) { return 5 },
	hLineColor: function (i, node) { return '#aaaaaa' }, vLineColor: function (i, node) { return '#aaaaaa' },
}
const fonts = {
	Roboto: {
		normal: 'Helvetica',
		bold: 'Helvetica-Bold',
		italics: 'Helvetica-Oblique',
		bolditalics: 'Helvetica-BoldOblique'
	},
	Courier: {
		normal: 'Courier',
		bold: 'Courier-Bold',
		italics: 'Courier-Oblique',
		bolditalics: 'Courier-BoldOblique'
	},
	Helvetica: {
		normal: 'Helvetica',
		bold: 'Helvetica-Bold',
		italics: 'Helvetica-Oblique',
		bolditalics: 'Helvetica-BoldOblique'
	},
	Times: {
		normal: 'Times-Roman',
		bold: 'Times-Bold',
		italics: 'Times-Italic',
		bolditalics: 'Times-BoldItalic'
	},
	Symbol: {
		normal: 'Symbol'
	},
	ZapfDingbats: {
		normal: 'ZapfDingbats'
	}
};

async function createCompanyContent(company, images) {
	const imageCompany = images.filter((entry) => entry.alias === 'Company')[0].base64;
	const content = []
	content.push(createTableHeadline(imageCompany, company.name));
	content.push(createTableSheet('COMPANY', company, 120));
	if (company.contactPersons && company.contactPersons.length > 0) {
		content.push(createCompanyContactPersons(company.contactPersons));
	}
	if (company.addresses && company.addresses.length > 0) {
		content.push(await createCompanyAddresses(company.addresses));
	}
	return content;
}

async function createEAACaseContent(eaaCase, images, eventClassifierStructure) {
	const imageEaaCase = images.filter((entry) => entry.alias === 'EAA-Case')[0].base64;

	const html = htmlToPdfmake(eaaCase.description, { defaultStyles })
	const tableEAACAseDescription = getDefaultTable([html]);
	tableEAACAseDescription.margin = [20, 0, 0, 0]
	const content = [
		createTableHeadline(imageEaaCase, eaaCase.name),
		createTableSheet('EAACASE', eaaCase, 150),
		{ text: 'Fallbeschreibung', style: 'headline' },
		tableEAACAseDescription
	]

	/* export Company */
	content.push({ ...horizontalLine });
	content.push(await createCompanyContent(eaaCase.company, images));

	/* export Case-Person */
	if (eaaCase.casePerson) {
		const imageCasePerson = images.filter((entry) => entry.alias === 'User')[0].base64;
		content.push(createTableHeadline(imageCasePerson, 'Person'));
		content.push(createTableSheet('CASEPERSON', eaaCase.casePerson, 150));
	}

	/* export Case-Person */
	if (eaaCase.caseUsers && eaaCase.caseUsers.length > 0) {
		content.push({ text: '', margin: [0, 10, 0, 0] });
		const imageCaseUsers = images.filter((entry) => entry.alias === 'Shield')[0].base64;
		content.push(createTableHeadline(imageCaseUsers, 'Baerbeiter*innen'));
		content.push(createCaseUsers(eaaCase.caseUsers));
	}

	if (eaaCase.caseEvents && eaaCase.caseEvents.legnth > 0) {
		const imageCalendar = images.filter((entry) => entry.alias === 'Calendar')[0].base64;
		const imageClock = images.filter((entry) => entry.alias === 'Clock')[0].base64;
		const imageCheckbox = images.filter((entry) => entry.alias === 'Checkbox')[0].base64;
		content.push({ ...horizontalLine });
		content.push(createCaseEvents(eaaCase.caseEvents, imageCalendar, imageClock, imageCheckbox, eventClassifierStructure))
	}

	return content
}


function createCaseEvents(caseEvents, imageCalendar, imageClock, imageCheckbox, eventClassifierStructure) {
	const content = [];
	caseEvents.forEach(caseEvent => {
		const eventClassifiers = caseEvent.caseEventClassifiers ? caseEvent.caseEventClassifiers : [];
		const html = htmlToPdfmake(caseEvent.description, { defaultStyles });
		const tableEventDescription = getDefaultTable([html]);
		tableEventDescription.margin = [20, 0, 0, 0]
		const bodyContent = [[createCaseEventHeadline(caseEvent, imageCalendar, imageClock)], [tableEventDescription], [createEvemtClassifiers(eventClassifiers, imageCheckbox, eventClassifierStructure)]];
		content.push(getDefaultTable([bodyContent]));
	});

	return content;
}

function createEvemtClassifiers(eventClassifiers, imageCheckbox, eventClassifierStructure) {

	const content = [];
	const allItems = [];
	const eventClassifiersList = eventClassifiers;

	if (!eventClassifierStructure) {
		return ''
	}
	const classifierStructure = eventClassifierStructure.map((structure) => {
		structure.items.forEach((item) => allItems.push(item))
		const structureItems = structure.items.map(item => item.caseEventClassifier)
		const items = eventClassifiersList.filter(item => structureItems.includes(item.caseEventClassifier))
		return { ...structure, items }
	}).filter(structure => structure.items.length > 0)

	classifierStructure.forEach((structure) => {
		const items = structure.items;
		items.sort((a, b) => {
			let x = a.caseEventClassifier.toLowerCase();
			let y = b.caseEventClassifier.toLowerCase();
			if (x < y) { return -1; }
			if (x > y) { return 1; }
			return 0;
		})
		content.push({ text: structure.headline, margin: [0, 5, 0, 2] });
		const bodyContent = [];
		items.map((item) => {
			const structureItem = allItems.find(entry => entry.caseEventClassifier === item.caseEventClassifier);
			let value = null;
			if (structureItem.fieldType === 'NUMBER') {
				value = { text: '' + item.count, fontSize: fontSize_S }
			} else if (item.count === 1) {
				value = { image: "data:image/png;base64," + imageCheckbox, width: 10, margin: [0, 2, 0, 0] };
			}
			if (value) {
				bodyContent.push([value, { text: structureItem.display, fontSize: fontSize_S }]);
			}
		})
		if (bodyContent.length > 0) {
			content.push({
				layout: 'noBorders', margin: [20, 0, 0, 0],
				table: {
					widths: [20, '*'], body: bodyContent
				}
			})
		}

	})
	return content;
}

function createCaseEventHeadline(caseEvent, imageCalendar, imageClock) {
	const text = 'Ersteller: ' + caseEvent.creator.displayname;
	return {
		layout: tableLayoutCaseEventHeadline,
		margin: [0, 0, 0, 5],
		table: {

			widths: [100, 8, 60, 8, 40, '*'],
			body: [[
				{ text: caseEvent.eventType.display, fontSize: fontSize_S },
				{ image: "data:image/png;base64," + imageCalendar, width: 10, margin: [0, 1, 0, 0], alignment: 'right' },
				{ text: format(parseISO(caseEvent.eventDate), 'yyyy-MM-dd'), fontSize: fontSize_S },
				{ image: "data:image/png;base64," + imageClock, width: 10, margin: [0, 1, 0, 0], alignment: 'right' },
				{ text: format(parseISO(caseEvent.eventDate), 'HH:mm'), fontSize: fontSize_S },
				{ text, fontSize: fontSize_S, alignment: 'right' }],
			]
		}
	}
}

async function createCompanyAddresses(addresses) {
	const rows = addresses.length % 2 === 0 ? parseInt(addresses.length / 2) : parseInt(addresses.length / 2) + 1;
	const bodyContent = [];
	for (let i = 0; i < rows; i++) {
		const address1 = addresses[i * 2];
		const address2 = (i * 2 + 1 < addresses.length ? addresses[i * 2 + 1] : null);
		const tableAddressRow = [{
			margin: [0, 0, 0, 10],
			layout: tableLayoutCard,
			table: { dontBreakRows: true, widths: ['*', 50, '*'], body: [[await createAddressCard(address1), {}, await createAddressCard(address2)]] }
		}];

		const bodyRows = i === 0 ? [[{ text: 'Adressen', style: 'headline' }], tableAddressRow] : [tableAddressRow];

		bodyContent.push([{
			layout: 'noBorders',
			table: { widths: ['*'], dontBreakRows: true, body: bodyRows }
		}])
	}
	return createTableForCards(bodyContent);
}

async function createAddressCard(address) {
	const stack = [];
	if (address) {
		await getData('addresses', address.id).then((address) => {
			stack.push({ text: address.addressType.display, style: 'normalTextBold', margin: [0, 0, 0, 5] });
			if (address.line1) { stack.push({ text: address.line1, style: 'smallerText' }) }
			if (address.line2) { stack.push({ text: address.line2, style: 'smallerText' }) }
			if (address.line3) { stack.push({ text: address.line3, style: 'smallerText' }) }
			if (address.postcode || address.city) { stack.push({ text: address.postcode + ' ' + address.city, style: 'smallerText' }) }
			if (address.country) { stack.push({ text: address.country, style: 'smallerText' }) }
		})
	}
	return !address ? '' : { stack };
}

function createCompanyContactPersons(contactPersons) {
	const rows = contactPersons.length % 2 === 0 ? parseInt(contactPersons.length / 2) : parseInt(contactPersons.length / 2) + 1;

	const bodyContent = [];
	for (let i = 0; i < rows; i++) {
		const contactPerson1 = contactPersons[i * 2];
		const contactPerson2 = (i * 2 + 1 < contactPersons.length ? contactPersons[i * 2 + 1] : null);
		const tableAddressRow = [{
			margin: [0, 0, 0, i < rows - 1 ? 10 : 0],
			layout: tableLayoutCard,
			table: { dontBreakRows: true, widths: ['*', 50, '*'], body: [[createContactPersonCard(contactPerson1), {}, createContactPersonCard(contactPerson2)]] }
		}];

		const bodyRows = i === 0 ? [[{ text: 'Anprechpartner*innen', style: 'headline' }], tableAddressRow] : [tableAddressRow];

		bodyContent.push([{
			layout: 'noBorders',
			table: { widths: ['*'], dontBreakRows: true, body: bodyRows }
		}])
	}
	return createTableForCards(bodyContent);
}

function createContactPersonCard(contactPerson) {
	const stack = [];
	if (contactPerson) {
		stack.push({ text: contactPerson.contactPerson.lastname + ', ' + contactPerson.contactPerson.firstname + ' - ' + contactPerson.contactType, style: 'normalTextBold' });
		stack.push({ text: contactPerson.contactPerson.jobDescription, style: 'normalTextBold', margin: [0, 0, 0, 5] });
		if (contactPerson.contactPerson.phone) { stack.push({ text: contactPerson.contactPerson.phone, style: 'smallerText' }) }
		if (contactPerson.contactPerson.mobile) { stack.push({ text: contactPerson.contactPerson.mobile, style: 'smallerText' }) }
		if (contactPerson.contactPerson.fax) { stack.push({ text: contactPerson.contactPerson.fax, style: 'smallerText' }) }
		if (contactPerson.contactPerson.mail) { stack.push({ text: contactPerson.contactPerson.mail, style: 'smallerText' }) }

	}
	return !contactPerson ? '' : { stack };
}

function createCaseUsers(caseUsers) {
	const rows = caseUsers.length % 2 === 0 ? parseInt(caseUsers.length / 2) : parseInt(caseUsers.length / 2) + 1;
	const content = [];
	for (let i = 0; i < rows; i++) {
		const caseUser1 = caseUsers[i * 2];
		const caseUser2 = (i * 2 + 1 < caseUsers.length ? caseUsers[i * 2 + 1] : null);
		const column1 = createCaseUserCard(caseUser1);
		const column2 = createCaseUserCard(caseUser2);
		content.push({
			margin: [0, 0, 0, 10],
			layout: tableLayoutCard,
			table: { dontBreakRows: true, widths: ['*', 50, '*'], body: [[column1, {}, column2]] }
		})
	}
	return content;
}

function createCaseUserCard(caseUser) {
	const stack = [];
	if (caseUser) {
		stack.push({ text: caseUser.caseuser.lastname + ', ' + caseUser.caseuser.firstname, style: 'normalTextBold' });
		stack.push({ text: caseUser.roleType.display, style: 'normalTextBold', margin: [0, 0, 0, 5] });
		if (caseUser.caseuser.phonenumber) { stack.push({ text: caseUser.caseuser.phonenumber, style: 'smallerText' }) }
		if (caseUser.caseuser.mobilenumber) { stack.push({ text: caseUser.caseuser.mobilenumber, style: 'smallerText' }) }
		if (caseUser.caseuser.email) { stack.push({ text: caseUser.caseuser.email, style: 'smallerText' }) }

	}
	return !caseUser ? '' : { stack };
}

async function createPDF(objectName, exportObject, eventClassifierStructure) {

	try {
		const images = await fetchIconsBase64();
		const logo = images.filter((entry) => entry.alias === 'EAA-Logo')[0].base64;
		let header = null;
		let content = null;
		if (objectName === 'EAACASE') {
			const title = `${exportObject.company.name}_${exportObject.name}`
			const subtitle = `${exportObject.caseDocId} vom ${format(parseISO(exportObject.date), 'dd.MM.yyyy')} - ${exportObject.status.display}`
			header = createPDFHeader(exportObject, logo, title, subtitle);
			content = await createEAACaseContent(exportObject, images, eventClassifierStructure)
		} else if (objectName === 'COMPANY') {
			console.log('company:', exportObject)
			const title = `${exportObject.name}`
			const subtitle = `#UN${exportObject.id} vom ${format(parseISO(exportObject.createdAt), 'dd.MM.yyyy')} - ${exportObject.active ? 'Aktiv' : 'Inaktiv'}`
			header = createPDFHeader(exportObject, logo, title, subtitle);
			content = await createCompanyContent(exportObject, images)
		}


		const pdfMake = require('pdfmake');
		pdfMake.vfs = pdfFonts.pdfMake.vfs;

		const docDefinition = {
			defaultStyles, styles, pageMargins: [80, 100, 50, 30],
			info: { title: `EAA Export vom ${format(new Date(), 'yyyy-MM-dd')}`, author: 'EAA' },
			footer: function (currentPage, pageCount) { return { text: 'Seite ' + currentPage.toString() + ' / ' + pageCount, alignment: 'right', style: 'headerdate', margin: [0, 0, 25, 0] } },
			header,
			content
		}
		console.log('docDefinition', docDefinition);
		pdfMake.createPdf(docDefinition).open();

	} catch (error) {
		if (error) { console.error(error) };
	}
}
function createPDFHeader(exportObject, logo, title, subtitle) {

	return {
		margin: [25, 10, 25, 0], layout: 'noBorders',
		table: {
			widths: [120, '*', 120],
			body: [
				[{}, {}, { text: `Export vom ${format(new Date(), 'yyyy-MM-dd')}`, alignment: 'right', style: 'headerdate' }],
				[{ image: "data:image/png;base64," + logo, width: 50 }, { text: title ? title : '', alignment: 'center' }, {}],
				[{}, { text: subtitle ? subtitle : '', alignment: 'center', style: { fontSize: fontSize_S }, margin: [0, 2, 0, 0] }, {}],
			]
		}
	}
}
function createTableHeadline(image, text) {
	const dontBreakRows = false;
	const layout = 'noBorders';
	const style = 'headline';
	const widths = [20, 'auto'];
	const rows = [{ image: "data:image/png;base64," + image, width: 15, margin: [0, 1, 0, 0] }, { text, bold: true }];
	return createTable({ layout, style, widths, rows, dontBreakRows });
}

function getDefaultTable(body) {
	return {
		layout: 'noBorders', margin: [0, 0, 0, 10],
		table: {
			widths: ['*'], dontBreakRows: false, body: [
				[{
					layout: 'noBorders',
					table: { widths: ['*'], dontBreakRows: true, body: body ? [body] : [[{ text: '' }]] },
				}],
			]
		}
	}
}

function createTable(config) {

	const embeddedTable = {
		table: { dontBreakRows: config.dontBreakRows ? config.dontBreakRows : false, widths: config.widths, body: [config.rows] }
	}
	if (config.margin) { embeddedTable.margin = config.margin }
	if (config.layout) { embeddedTable.layout = config.layout }
	if (config.style) { embeddedTable.style = config.style }

	const table = [{
		layout: 'noBorders', margin: [0, 0, 0, 10],
		table: {
			widths: ['*'], dontBreakRows: false, body: [
				[{
					layout: 'noBorders',
					table: { widths: ['*'], dontBreakRows: true, body: [[embeddedTable]] },
				}],
			]
		}
	}]

	return config.embedded ? table : embeddedTable;
}

function createTableForCards(bodyContent) {
	const table = [{
		layout: 'noBorders',
		table: {
			widths: ['*'], dontBreakRows: false, body: [
				[{
					layout: 'noBorders',
					table: { widths: ['*'], dontBreakRows: true, body: bodyContent },
				}],
			]
		}
	}]
	return table;
}

function createTableSheet(objectName, exportObject, width) {
	const body = [];
	if (objectName === 'EAACASE') {
		body.push(['Unternehmen', exportObject.company.name]);
		if (exportObject.responsible) { body.push(['Verantwortliche*r', exportObject.responsible.displayname]) }
		if (exportObject.caseCreator) { body.push(['Ersteller*in', exportObject.caseCreator.displayname]) }
		body.push(['Initiiert durch', exportObject.initiatedBy.display]);
		if (exportObject.documentLocation) { body.push(['Dateipfad Dokument', exportObject.documentLocation]) }
	} else if (objectName === 'COMPANY') {
		if (exportObject.phone) { body.push(['Telefon:', exportObject.phone]) }
		if (exportObject.fax) { body.push(['Fax:', exportObject.fax]) }
		if (exportObject.mail) { body.push(['E-Mail:', exportObject.mail]) }
		body.push(['Mitarbeiter:', exportObject.employees]);
		body.push(['Mitarbeiter SbM:', exportObject.employeesSbM]);
		if (exportObject.chamber) { body.push(['Kammer:', exportObject.chamber]) }
		body.push(['EAA Region:', exportObject.region.name]);
	} else if (objectName === 'CASEPERSON') {
		body.push(['Person ID:', exportObject.personId]);
		if (exportObject.GdBMark) { body.push(['Grad der Behinderung Merkzeichen:', exportObject.GdBMark]) }
		if (exportObject.GdB) { body.push(['Grad der Behinderung:', exportObject.GdB]) }
		if (exportObject.disablityKind) { body.push(['Art der Behinderung:', exportObject.disablityKind]) }
		if (exportObject.gender) { body.push(['Geschlecht:', exportObject.gender.alias]) }
	}
	return {
		layout: 'noBorders',
		style: 'tableBody',
		table: {
			widths: [width, 'auto'],
			body
		}
	}
}



const exportToPDF = async (objectName, exportObject, eventClassifierStructure) => {
	createPDF(objectName, exportObject, eventClassifierStructure);
}

export default exportToPDF;